import Script from "next/script";
import Head from "next/head";
import { useEffect } from "react";
import { Fragment } from "react";
import { Provider } from "react-redux";
import Store from "../redux/store/index";
import'@assets/scss/main.scss'

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    import('swiper/css/navigation');
import('swiper/css/pagination');
import('swiper/css/scrollbar');
import('swiper/css');
import('../styles/globals.css');
import('@assets/fonts/fontawesome-all.min.css'); 
  }, []); 

  return (
    <Fragment>
      <Head>
        <meta
          name="viewport"
          content="maximum-scale=3, initial-scale=1.0, width=device-width, shrink-to-fit=no, viewport-fit=cover"
        />
      </Head>
      <Script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" 
        strategy="lazyOnload"
        integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL"
        crossorigin="anonymous"
      >
      </Script>
      <Script
        src = "https://embed.tawk.to/621cbd3f1ffac05b1d7c38b0/1ft046gkk"
        strategy="lazyOnload"
        crossorigin="*"
      >
      </Script>
      <Provider store={Store}>
        <Component {...pageProps} />
      </Provider>
    </Fragment>
)}
export default MyApp;