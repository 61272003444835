import { createSlice } from "@reduxjs/toolkit";
export const blogSlice = createSlice({
  name: "CreateBlogLabel",
  initialState: {
    label: "All",
  },
  reducers: {
    addTaskToList: (state, action) => {
      state.label = action.payload;
    },
  },
});
export const { addTaskToList } = blogSlice.actions;
export default blogSlice.reducer;